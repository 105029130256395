<template>
  <div class="main-content_title container">
    <h1 class="main-content_title">Terms of Use</h1>
    <h3>Acceptance of Terms</h3>
    <p class="line-style__1">
      By accessing this Website, you acknowledge that you have read, understood and agreed to the following terms. In
      case you do not understand or agree to any of the terms, you should immediately exit this Website. HOLOWITS
      TECHNOLOGIES PTE. LTD. and/or its affiliates (collectively "HOLOWITS") reserves the right to update the TERMS OF
      USE ("TOU") at any time without notice to you. In the case of any violation against the provisions of the TOU,
      HOLOWITS shall have the right to seek legal and fair remedies.
    </p>
    <h3>Our Website Content</h3>
    <p class="line-style__1">
      The Website Contents include but are not limited to text, images, data, points of views, suggestions, web pages,
      and links offered on or through the Website. This Website and its contents are provided for your convenience only.
      Although HOLOWITS has attempted to provide accurate information on this Website, it assumes no obligation or
      responsibility whatsoever regarding the accuracy and completeness of the information. HOLOWITS may change the
      contents available on this Website or the products mentioned at any time and without notice.
    </p>
    <p class="line-style__1">
      ALL INFORMATION PROVIDED ON THIS WEBSITE IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES, GUARANTEES OR
      REPRESENTATIONS OF ANY KIND. HOLOWITS HEREBY EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL
      EXPRESS, IMPLIED, STATUTORY OR OTHER WARRANTIES, GUARANTEES OR REPRESENTATIONS, INCLUDING BUT NOT LIMITED TO, THE
      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
    </p>
    <h3>Software Content</h3>
    <p class="line-style__1">
      HOLOWITS or its licensors own and retain all rights, including all applicable intellectual property rights, in all
      software on this website, in both source and binary forms, sample code, APIs, SDKs, associated documentation, and
      other related materials (collectively, "Software Content").
    </p>
    <p class="line-style__1">
      Unless you have obtained relevant license through other agreement entered between you and HOLOWITS, nothing in
      this TOU grants any right or license to Software Content to you, and you shall not:
    </p>
    <ul class="research">
      <li>reverse engineer, decompile, disassemble, split, adapt, implant, or implement other derivative works on the
        Software Content;
      </li>
      <li>research the internal implementation of HOLOWITS products, obtain product source code, or steal intellectual
        property rights in any manner; and/or
      </li>
      <li>disclose results of any benchmark tests.</li>
    </ul>
    <h3>Your Use of this Website</h3>
    <p class="line-style__1">
      You shall not use this Website and/or the Website Content for any purpose that is illegal or forbidden by the TOU,
      in connection with any illegal purposes, or to solicit the performance of any illegal activity or activities that
      infringe on the rights of HOLOWITS or any third party.
    </p>
    <p class="line-style__1">
      You shall not gain unauthorized access to any portion of this Website, or to any of the services offered on or
      through the Website, or to any other systems or networks connected to any HOLOWITS server.
    </p>
    <p class="line-style__1">
      You shall not take any action that will place an excessive load on this Website, including but not limited to its
      infrastructure, systems, and networks.
    </p>
    <p class="line-style__1">
      You shall not obtain, or attempt to obtain, any Website Content through any means not purposely available through
      this Website or use any manual or automatic process, device, program, algorithm, or methods to access, obtain,
      copy, or monitor any portion or content of this Website.
    </p>
    <p class="line-style__1">
      You shall not test by scanning or other means the vulnerability of this Website or any networks connected to this
      Website, nor breach the security or authentication measures of this Website and the services offered on or through
      this Website. You shall not reverse lookup, trace, or attempt to trace any information of any other users or
      visitors of HOLOWITS or this Website, or exploit or reveal any information or services offered on or through this
      Website.
    </p>
    <p class="line-style__1">
      You shall not interfere with or attempt to interfere with the proper operation of this Website and other person's
      use of this Website by any means, including but not limited to using any device, software, or program.
    </p>
    <p class="line-style__1">
      When using this Website and its content, you agree to comply with applicable laws and obey social ethics. You
      shall not use this Website and its content to produce, read, copy, or disseminate any information that is illegal,
      to infringe on any other person's rights, or to disturb the social order and undermine social stability. You shall
      not use this Website and its content to engage in any activity that endangers cyber security and any computer
      system.
    </p>
    <h3>Trademark</h3>
    <p class="line-style__1">
      All trademarks and logos displayed, mentioned or otherwise used in this Website are property of HOLOWITS or its
      licensors as stated if applicable. You are not permitted to use any of these trademarks or logos in any way
      without explicit prior written permission from HOLOWITS or such licensors as applicable.
    </p>
    <h3>Privacy</h3>
    <p class="line-style__1">
      HOLOWITS's Privacy Policy is applicable to the use of personal information on or through this Website. By using
      this Website, you agree that HOLOWITS can use such information in accordance with this Privacy Policy.
    </p>
    <h3>User Accounts and Profiles</h3>
    <p class="line-style__1">
      In order to access this Website or obtain certain functions or services of our Website, you may be required to
      create an account, set up a user profile, or download HOLOWITS software. Additional HOLOWITS terms or requirements
      for the certain functions or services may apply. In case of any conflict between such additional terms and the
      TOU, the additional terms shall prevail for the aforementioned certain functions or services.
    </p>
    <p class="line-style__1">
      You shall not impersonate another person or entity, or forge your identity to access this Website. If any portion
      of the Website requires you to create an account or set up a user profile, the information you provide must be
      truthful and accurate. You agree to keep your account information secure and confidential and to never allow
      another person or entity to access your account or profile using your username or password at any time, otherwise
      you may be held liable for the losses incurred by HOLOWITS or any other users or visitors to this Website.
    </p>
    <p class="line-style__1">
      HOLOWITS reserves the right, at its sole discretion, to reject or remove your post, restrict, suspend, or
      terminate your access to the Website, and may retain or delete your information or content.
    </p>
    <h3>Copyright</h3>
    <p class="line-style__1">
      All contents on this Website are copyrighted by HOLOWITS or applicable licensors unless otherwise stated. Without
      prior written consent of HOLOWITS or other related parties, any content on the Website shall not be reproduced,
      modified, promoted, distributed, photocopied, played, disassembled, reverse engineered, decompiled, linked or
      transmitted with hyperlinks, loaded into other servers in "mirroring method", stored in information retrieval
      system, or otherwise used for any other purpose by any person by any means.
    </p>
    <h3>Product Availability</h3>
    <p class="line-style__1">
      The availability of the products and services described on this Website, and the descriptions of such products and
      services, may vary in your country or region and include products and services not launched in your country or
      region. Please consult your local HOLOWITS sales representative or agent for specific products and/or services
      information.
    </p>
    <h3>Links to Third Parties</h3>
    <p class="line-style__1">
      Although links to third party Websites may be contained in this Website for your convenience, HOLOWITS shall not
      be responsible for any content of any such Websites. You might need to review and agree to applicable rules of use
      when using such Websites. In addition, a link to third-party Website does not imply that HOLOWITS endorses the
      site or the products or services referenced therein.
    </p>
    <h3>Limitation of Liability</h3>
    <p class="line-style__1">
      Neither HOLOWITS nor any of its affiliates, subsidiaries, directors, agents, employees or other representatives
      shall be liable for any direct, indirect, special, incidental, consequential, punitive, and/or exemplary damages
      including without limitation, loss of profits or revenues, loss of data, and/or loss of business, in connection
      with this Website or the use of or inability to use this Website or reliance on the contents contained herein,
      even if HOLOWITS is advised of the possibility of such damages. Unless otherwise agreed in writing by HOLOWITS in
      any sales contract, to the extent permitted by the applicable law, HOLOWITS shall not be liable for the materials
      of this Website, regardless of the reason of the liability or the tort theory it based on.
    </p>
    <h3>Applicable Law and Dispute Resolution</h3>
    <p class="line-style__1 margin_bottom-50">
      You agree that your access and all related activities on or through this Website shall be governed by, construed,
      and interpreted in accordance with the laws of the Republic of SINGAPORE ou agree that any dispute between the
      parties arising out of or in connection with this TOU or your access and all related activities on or through this
      Website shall be governed by a court with jurisdiction in SINGAPORE.
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse"
}
</script>

<style scoped lang="less">
.main-content_title {
  .main-content_title {
    text-align: center;
    margin-top: 30px;
  }

  .line-style__1 {
    font-size: 18px;
    line-height: 35px;
    margin-top: 20px;
  }

  .research {
    font-size: 18px;
    list-style: disc;
    line-height: 35px;
    margin-left: 40px;
  }
  h3{
    padding-top: 20px;
  }
  .margin_bottom-50{
    margin-bottom: 50px;
  }
}
</style>